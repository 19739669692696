.privacy-policy {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    color: #333;
  
    &__container {
      max-width: 800px;
      margin: 0 auto;
      background-color: #fff;
      padding: 40px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  
    &__title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      text-align: center;
      color: #2c3e50;
    }
  
    &__date {
      font-size: 1rem;
      margin-bottom: 30px;
      text-align: center;
      color: #7f8c8d;
    }
  
    &__section {
      margin-bottom: 40px;
    }
  
    &__section-title {
      font-size: 1.75rem;
      margin-bottom: 20px;
      color: #2980b9;
    }
  
    &__subsection-title {
      font-size: 1.5rem;
      margin-bottom: 15px;
      color: #3498db;
    }
  
    &__text {
      font-size: 1rem;
      margin-bottom: 20px;
      line-height: 1.6;
  
      ul {
        padding-left: 20px;
        list-style-type: disc;
  
        li {
          margin-bottom: 10px;
        }
      }
  
      strong {
        color: #34495e;
      }
    }
  
    &__text:last-child {
      margin-bottom: 0;
    }
  }
  