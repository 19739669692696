@import "../../scss/index.scss";

.nav {
  background-color: #1a184d;
  font-family: $poppins;

  .navbar_top {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    z-index: 999;

    img {
      width: 100px;
    }

    .barra {
      list-style: none;
      display: flex;
      gap: 15px;

      a {
        margin-top: 10px;
        font-size: 15px;
        cursor: pointer;
        transition: all 0.3s ease;
        text-decoration: none;
        color: black;

        &:hover {
          background: linear-gradient(90deg, #2575cb, #33aae8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
          transform: scale(1.05);
        }
      }
    }

    .button_container {
      display: none;

      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  // Estilos para el menú desplegable móvil
  .barra.dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1a184d;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 0;
    z-index: 1000;

    // Estilos para la animación de aparición
    &.open {
      animation: slideDown 0.3s ease forwards; // Aparece
    }

    // Estilos para la animación de cierre
    &.close {
      animation: slideUp 0.3s ease forwards; // Desaparece
    }

    img {
      width: 100px;
      margin-bottom: 30px;
    }

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    a {
      color: white;
      font-size: 20px;
      margin: 5px 0;
      font-weight: normal;
      text-decoration: none;

      
      &:hover {
        background: linear-gradient(90deg, #2575cb, #33aae8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        transform: scale(1.05);
      }
    }

    // Estilo para el botón de cierre en la esquina superior derecha
    .close-button {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      width: 25px;
      height: 25px;
      fill: white;
    }
  }

  // Estilos para pantallas menores a 991px
  @media (max-width: 991px) {
    .navbar_top {
      padding: 10px 20px;

      .barra {
        display: none;
      }

      .button_container {
        display: block;
      }
    }
  }
}

// Animación de despliegue
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Animación de cierre
@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}
