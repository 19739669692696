$carta-bg-color: #f9f9f9;
$carta-border-color: #ccc;
$carta-heading-color: #333;

.carta-responsiva {
  background-color: $carta-bg-color;
  border: 1px solid $carta-border-color;
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  h1 {
    color: $carta-heading-color;
    font-size: 24px;
    margin-bottom: 20px;
  }

  h2 {
    color: $carta-heading-color;
    font-size: 20px;
    margin-top: 20px;
  }

  p {
    color: #555;
    line-height: 1.6;
  }

  ol {
    margin: 20px 0;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
}
