@import '../../scss/index.scss';

.downloadSection{
    .container{
        margin-top: 20px;
        padding: 50px;
        background: linear-gradient(70deg, #2575CB, #33AAE8); 
        border-radius: 15px;
        
        .data_info{
            h1{
                color: white;
                font-weight: bold;
                text-align: center;
            }
            p{
                color: white;
                text-align: justify;
            }
        }
        .icons{
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 119px;
                height: 52px;
                margin-right: 20px;
              }   
        }
    }
}