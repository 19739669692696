
@import '../../scss/index.scss';
.check{
display: flex;
flex-direction: row;
img{
    width: 30px;
    height: 30px;
    color: #1A184D !important;
    margin-right: 10px;
    margin-top: 15px;
}
p{
    margin-top: 10px;
    .title{
        background: linear-gradient(90deg, #2575CB, #33AAE8); // Definir el gradiente
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
}