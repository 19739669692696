@import '../../scss/index.scss';
.banner {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between; // Asegura que los elementos se distribuyan entre la parte superior e inferior
      align-items: center;
      align-content: space-between;
  
      .descripcion {
        margin-top: 150px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            max-width: 600px;
          font-family: $poppins;
          font-weight: bold;
          margin-bottom: 30px;
          background: linear-gradient(90deg, #2575CB, #33AAE8); // Definir el gradiente
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 50px;
        }
  
        p {
          font-family: $poppins;
          margin-bottom: 30px;
          max-width: 800px;
          color: white;
        }
        .container_logos{
        img {
          width: 119px;
          height: 52px;
          margin-right: 20px;
        }    
        }  
      }
  
      .image_fondo {
        margin-top: auto; 
        display: flex;
        justify-content: center;
        align-items: flex-end; // Alinea la imagen en la parte inferior de la sección
        width: 100%;
  
        img {
          width: 100%;
        }
      }
    }
  }
  