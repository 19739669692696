@import '../../scss/index.scss';
.ventajas{

    .container{
        margin-top: 20px;
        padding: 50px;
        background-color: white;
        border-radius: 15px;
        .row{
           .points{
            h1{font-weight: bold;
                background: linear-gradient(90deg, #2575CB, #33AAE8); // Definir el gradiente
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-align: center;
                margin-bottom:30px;
            }
           }
           .image_container{
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;

            .img_phone{
                width: 100%;
            }
           }
        }
    }
}