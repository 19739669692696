@import "../../scss/index.scss";

.footer{
    background-color: #1a184d;
    font-family: $poppins;
    padding-bottom: 10px;
    .container{
        background-color: white;
    border-radius: 15px;
    padding: 20px;
    .top{
        .info{
            p{
                text-align: justify;
                margin-top: 10px;
                font-size: 13px;
            }
        }
        .atajos{
            display: flex;
            flex-direction: column;
            .title{
                font-weight: bold;
            }
            a{
                text-decoration: none;
                color: black;
            }
        }
        .contacto{
            .title{
                font-weight: bold;
            }
          
        }
    }
    .button{
        display: flex;
        justify-content: center;
        p{
            margin-top: 10px;
            font-weight: bold;
            background: linear-gradient(90deg, #2575cb, #33aae8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0px;
        }
    }
    }
}